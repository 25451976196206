import { FETCHED_SUBJECTS_FOR_PERIOD } from '../../actions/areas/types';

const initialState = {
	forPeriods: []
};

export default function (state = initialState, action) {

	let result = state;

	switch (action.type) {
		case FETCHED_SUBJECTS_FOR_PERIOD:
			result = {
        ...state,
        forPeriods: action.payload
      }; 
      break;
		default:
      result = state; 
      break;
	}

	return result;
}