import {
	TuitionModel
} from "./models"

const generateRows = (generator = null, numberPages = 1, rowsForPage = 10) => {

	if (generator == null)
		throw "Error: Require callback generator function";

	let rows = []
	for (let i=0; i<numberPages; i++) {
		let page = [];
		for (let j=0; j<rowsForPage; j++) {
			page.push(generator());
		}
		rows.push(page);
	}
	return rows;
};

const models = {
	"tuition": TuitionModel
};

let Factory = (modelname = null) => {

	if (modelname == null)
		return [];

	const model = models[modelname];

	return generateRows(
		model.generator,
		model.numberPages,
		model.rowsForPage
	);
};

export default Factory;