import { FETCHED_PERIODS } from '../../actions/periodos/types';

const initialState = {
	rows: []
};

export default function (state = initialState, action) {

	let result = state;

	switch (action.type) {
		case FETCHED_PERIODS:
			result = {
        ...state,
        rows: action.payload
      }; 
      break;
		default:
      result = state;
      break;
	}

	return result;
}