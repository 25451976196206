import { combineReducers } from 'redux';
import user from './user';
import menu from './menu';
import alumnos from './alumnos';
import profesores from './profesores';
import usuarios from './usuarios';
import horarios from './horarios';
import periodos from './periodos';
import secciones from './secciones';
import areas from './areas';
import recursos from './recursos';

export default combineReducers({
  user,
  menu,
  alumnos,
  profesores,
  usuarios,
  horarios,
  periodos,
  secciones,
  areas,
  recursos
});