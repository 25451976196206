import { FETCHED_MENUS } from '../../actions/menu/types';

const initialState = {
	menus: []
};

export default function (state = initialState, action) {

	let result = state;

	switch (action.type) {
		case FETCHED_MENUS:
			result = {
        ...state,
        menus: action.payload
      }; 
      break;
		default:
      result = state; 
      break;
	}

	return result;
}