import { FETCHED_MENUS } from './types';
import { request } from 'graphql-request';
import { URL } from '../../../.env';

export function getMenus (rol_id) {

	const query = `{
    menus(rol: ${rol_id}) {
      id,
      nombre,
      ruta,
      icono
    }
  }`;
  
	return dispatch => {
		request(URL + '/graphqll', query).then(data => {
		  dispatch({
		    type: FETCHED_MENUS,
		    payload: data.menus
		  }); 
		})
	}
}