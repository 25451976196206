import { FETCHED_SCHEDULES, ADD_SCHEDULE, UPDATE_SCHEDULE, DROP_SCHEDULE } from '../../actions/horarios/types';

const initialState = {
	scheludes: []
};

export default function (state = initialState, action) {

	let result = state;

	switch (action.type) {
		case FETCHED_SCHEDULES:
			result = {
				...state,
				scheludes: action.payload
			}; 
      break;
    case ADD_SCHEDULE:
      result = {
        ...state
      }; 
      break;
    case UPDATE_SCHEDULE:
      result = {
        ...state
      };
      break;
    case DROP_SCHEDULE:
      let scheludes = state.scheludes.filter(schelude => schelude.id !== action.payload);
      result = {
        ...state,
        scheludes
      };
      break;
		default:
      result = state; 
      break;
	}

	return result;
}