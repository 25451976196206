/*eslint-disable*/

import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux'

import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col
} from "reactstrap";

import { URL } from '../../.env.js';

import { saveProfile } from '../../redux/actions/user';

const axios = require('axios');
const swal  = require('sweetalert');

function Login() {

  const dispatch = useDispatch();

  let history = useHistory();

  const loggedIn = useSelector(store => store.loggedIn);

  const [mensaje,  setMensaje]  = useState('Ingrese sus credenciales');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [contenedorUsuario,    setContenedorUsuario]    = useState('input-group-alternative');
  const [campoUsuarioInvalido, setCampoUsuarioInvalido] = useState('');
  const [contenedorClave,      setContenedorClave]      = useState('input-group-alternative');
  const [campoClaveInvalido,   setCampoClaveInvalido]   = useState('');

  useEffect(() => {
    if (loggedIn)
      history.push("/admin/index");
  });

  function handleSubmit() {

    // Resetear indicadores de validaciones
    setContenedorUsuario('input-group-alternative');
    setContenedorClave('input-group-alternative');
    setCampoUsuarioInvalido('');
    setCampoClaveInvalido('');

    // ¿Completo los campos?
    if (username !== '' && password !== '') {

      const cedula = 'V-' + username;

      axios({
        method: 'post',
        url: URL+'/login?username='+cedula+'&password='+password,
        withCredentials: true
      })
        .then(function (response) {

          if (!response.data) {
            swal({
              title: "Credenciales invalidades",
              text: "Por favor verifique los datos ingresados",
              icon: "warning",
              button: "Aceptar"
            })
          } else {
            dispatch(saveProfile(response.data));
            history.push("/admin/index");
          }

        })
        .catch(function (error) {
          console.log(error.message);
          swal({
            title: "Error de conexión",
            text: "Por favor verifique su conexión a internet",
            icon: "warning",
            button: "Aceptar"
          });
        });

    } else {

      // Validar campos vacios
      if (username === '') 
      {
        setContenedorUsuario('input-group-alternative has-danger');
        setCampoUsuarioInvalido('is-invalid');
      } 
      if (password === '') 
      {
        setContenedorClave('input-group-alternative has-danger');
        setCampoClaveInvalido('is-invalid');
      }

    }
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>{mensaje}</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className={contenedorUsuario}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-address-card" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input className={campoUsuarioInvalido} placeholder="Cédula" type="text" autoComplete="new-email" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className={contenedorClave}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input className={campoClaveInvalido} placeholder="Contraseña" type="password" autoComplete="new-password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="button" onClick={() => handleSubmit()} data-testid="btn-login">
                  Ingresar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default Login;