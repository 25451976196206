// import Index from "./views/Index.js";
// import Profile from "./views/examples/Profile.js";
// import Maps from "./views/examples/Maps.js";
// import Register from "./views/examples/Register.js";
// import Tables from "./views/examples/Tables.js";
// import Icons from "./views/examples/Icons.js";

import React from "react";
import Loader from "./components/Utils/Loader";
import Login from "./views/auth/Login.js";
import DetailsAssignment from "./views/shared/DetailsAssignment.js";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-prima",
    component: Loader,
    layout: "/admin"
  },
  {
    path: "/assignment/:id",
    name: "Detalles de asignación",
    icon: "ni ni-key-25 text-info",
    component: DetailsAssignment,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  }
];

/* var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Loader,
    layout: "/admin"
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin"
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin"
  },
  {
    path: "/assignment/:id",
    name: "Detalles de asignación",
    icon: "ni ni-key-25 text-info",
    component: DetailsAssignment,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  }
]; */

export default routes;