const faker = require('faker');

faker.seed(123);

// Generate students for general tuitition
export const generateStudent = () => ({
	"cedula": "V-" + faker.random.number(10000000, 35999999).toString(),
	"nombres": faker.name.findName().toUpperCase(),
	"sexo": faker.random.arrayElement(["M", "F"]),
	"estado": faker.random.arrayElement(["Activo", "Pendiente", "Bloqueado"]),
	"periodo": faker.random.number(6).toString(),
	"seccion": "U"
});