/**
 * Convert 1 in 1ero
 * 
 * @param  {string|int} number
 * @return {string}
 */
export function getSufijoNumber(number) {
  switch (number.toString()) {
    case '1':
      return '1ero';
    case '2':
      return '2do';
    case '3':
      return '3ero';
    case '4':
      return '4to';
    case '5':
      return '5to';
    case '6':
      return '6to';
    case '7':
      return '7mo';
    case '8':
      return '8vo';
    case '9':
      return '9no';
    default:
      return '1ero';
  }
}

/**
 * Get name weekday
 * 
 * @param  {int} numberDay
 * @return {string}
 */
export function getNameDay(numberDay) {
  switch (numberDay) {
    case 1:
      return 'Lunes';
    case 2:
      return 'Martes';
    case 3:
      return 'Miercoles';
    case 4:
      return 'Jueves';
    case 5:
      return 'Viernes';
    case 6:
      return 'Sabado';
    case 0:
      return 'Domingo';
    default:
      return 'Lunes';
  }
}

/**
 * Get name month
 * 
 * @param  {int} numberMonth
 * @return {string}
 */
export function getNameMonth(numberMonth) {
  switch (numberMonth) {
    case 0:
      return 'Enero';
    case 1:
      return 'Febrero';
    case 2:
      return 'Marzo';
    case 3:
      return 'Abril';
    case 4:
      return 'Mayo';
    case 5:
      return 'Junio';
    case 6:
      return 'Julio';
    case 7:
      return 'Agosto';
    case 8:
      return 'Septiembre';
    case 9:
      return 'Octubre';
    case 10:
      return 'Noviembre';
    case 11:
      return 'Diciembre';
    default:
      return 'Enero';
  }
}

/**
 * Get name with format
 * GONZALEZ RUJANO, LEONARDO JOSE -> Leonardo Gonzalez
 * 
 * @param  {string} fullname
 * @return {string}
 */
export function getNameFormat(fullname) {

  const separator = (fullname.search(",") === -1) ? " " : ",";

  let lastnames = fullname.split(separator)[0];
  let names     = fullname.split(separator)[1];

  let lastname = lastnames.split(" ")[0];
  let name   = names.includes(" ") ? names.split(" ")[1] : names;

  lastname = lastname.substring(0, 1) + lastname.substring(1).toLowerCase();
  name   = name.substring(0, 1) + name.substring(1).toLowerCase();

  return name + " " + lastname;
}

/**
 * Transform "LOREM SUM" in "Lorem sum"
 * 
 * @param  {string} title
 * @return {string}
 */
export function formatTitleToDiminuto(title) {
  return title.substring(0, 1) + title.substring(1).toLowerCase();
}

/**
 * Text contain double quotation
 *
 * @param  {string} text
 * @return {boolean}
 */
export function containDoubleQuotation(text) {
  return (text.indexOf('"') !== -1);
}

/**
 * Get icon for type file
 * "archivo.pdf" - "icons/pdf.png"
 *
 * @param  {string} fileName
 * @return {string}
 */
export function getIconTypeFile(fileName) {

  fileName = fileName.toLowerCase().split('.')[1];

  switch (fileName) {
    case 'docx':
      return 'word.png';
    case 'xls':
    case 'xlsx':
      return 'excel.png';
    case 'pptx':
      return 'powerpoint.png';
    case 'pdf':
      return 'pdf.png';
    case 'mp3':
    case 'wav':
      return 'audio.png';
    case 'jpg':
    case 'png':
      return 'photo.png';
    case 'mp4':
    case 'webm':
    case 'avi':
    case '3gp':
      return 'video.png';
    default:
      return 'other.png';
  }
}