/*eslint-disable*/

import React, { useState } from "react";

// Reactstrap Components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Progress,
  Label,
  FormText,
  Table,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalHeader, 
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from "reactstrap";

// Firebase dependencies
import 'firebase/storage';

// Core Components
import Header from "components/Headers/Header.js";

import { useSelector, useDispatch } from "react-redux";

import { useParams } from "react-router-dom";

let moment = require('moment');

import {
  getSufijoNumber,
  getNameDay,
  getNameMonth,
  getNameFormat,
  formatTitleToDiminuto,
  containDoubleQuotation
} from "../../util";

import firebase from "firebase";

import { createAnswer } from "../../redux/actions/alumnos";

import "./DetailsAssignments.css";

/**
 * Download file of storage
 * 
 * @param  {string} filepath
 * @return {void}
 */
function downloadResource(filepath) {
  window.storageRef.child(filepath).getDownloadURL().then(function(url) {
    window.open(url);
  }).catch(function (err) {
    swal({
      title: "Error de conexión",
      text: "A ocurrido un error al intentar descargar el material",
      icon: "warning",
      button: "Aceptar"
    });
  });
}

// Component list answers
function ListAnswers(props) {

  // Status Open/Closed modal
  const [modal, setModal] = useState(false);
  const toggle = () => { setModal(!modal); }

  // Effect show/hide icons actions

  // Comment now data
  const [comment, setComment] = useState({message: "", author: ""});

  function getAnswers(answers) {

    return answers.map(answer => {
      return (
        <>
          <tr className="item-list-answer">
            <th scope="row">{answer.alumno.nombres}</th>
            <td>{answer.alumno.cedula}</td>
            <td>{moment(answer.fecha).format('DD-MM-YYYY')} - {answer.hora.substring(0, 5)}</td>
            <td>
              <Row>
                <Col style={{paddingRight: "0px"}}>
                  <i
                    className="fas fa-envelope option-answer"
                    data-placement="top"
                    id="tooltip611234743"
                    onClick={() => {
                      setComment({message: answer.descripcion, author: answer.alumno.nombres + " - " + answer.alumno.cedula});
                      toggle();
                    }}
                  />
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target="tooltip611234743"
                  >
                    Comentario
                  </UncontrolledTooltip>
                </Col>
                <Col style={{paddingLeft: "10px"}}>
                  <i
                    className="fas fa-file-alt option-answer"
                    data-placement="top"
                    id="tooltip611234744"
                    onClick={() => downloadResource("answers/" + answer.ubicacion)}
                  />
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target="tooltip611234744"
                  >
                    Trabajo
                  </UncontrolledTooltip>
                </Col>
              </Row>
            </td>
          </tr>
        </>
      );
    });
  }

  return(
    <>
      <h6 className="heading-small text-muted mb-4">Entregas recibidas</h6>
      <div className="pl-lg-4">
        { props.answers.length > 0 ?
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">Alumno</th>
              <th scope="col">Cédula</th>
              <th scope="col">Fecha de entrega</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {getAnswers(props.answers)}
          </tbody>
        </Table>
        : "Ninguna" }
      </div>

      {/* Modal view coment */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h3>Comentario</h3>
        </ModalHeader>
        <ModalBody>
          {comment.message}
          <br></br>
        </ModalBody>
        <ModalFooter>
          <strong style={{fontSize: "0.8em"}}>{comment.author}</strong>
        </ModalFooter>
      </Modal>
    </>
  );
}

// Component detail answer
function DetailAnswer(props) {

  return(
    <>
      <h6 className="heading-small text-muted mb-4">Datos de entrega</h6>
      <div className="pl-lg-4">
        {/* Comment */}
        <label>Comentario</label>
        <br/>
        <p>{props.answer.descripcion}</p>
        {/* Download action */}
        <label style={{marginBottom: '15px'}}>Trabajo entregado</label>
        <br/>
        <Button className="btn-icon btn-3" color="info" outline type="button">
          <span className="btn-inner--text" onClick={() => downloadResource("answers/" + props.answer.ubicacion)}>Descargar</span>
        </Button>
      </div>
    </>
  );
}

// Component form send answer
function FormSendAnswer(props) {

  // Dispatch of Redux
  const dispatch = useDispatch();

  // Get ID current student
  const studentId = useSelector(store => store.alumnos.currentStudent.id);

  // Status file to upload
  const [isUploading, setIsUploading] = useState(false);
  const [progressPorcentage, setProgressPorcentage] = useState(0);

  // Values of Form answer
  const [comment, setComment]     = useState("");
  const [file,    setFile]        = useState("");

  // Seed for name file
  let seedName = new Date();
  seedName = seedName.getTime();

  /**
   * Send data answer
   *
   * @return {void}
   */
  function addAnswer() {

    // Check valid form
    if (!isValidForm())
      return;

    // Confirm send form
    swal({
      title: "¿Estas seguro?",
      text: "Una vez enviada la respuesta no podras modificarla",
      icon: "warning",
      buttons: {
        cancel: "No",
        ok:     "Si"
      },
      dangerMode: false
    })
      .then(async (willSend) => {

        if (willSend === "ok") {

          const extension = file.name.split(".").pop();

          const answer = {
            descripcion:   comment,
            asignacion_id: props.assignmentId,
            alumno_id:     studentId,
            fecha:         moment().format("YYYY-MM-DD"),
            hora:          moment().format("HH:mm:ss"),
            ubicacion:     seedName + "." + extension
          };

          await uploadFile();

          // Send data of assignment
          dispatch(createAnswer(answer, props.user.cedula));
        }
      });
  }

  /**
   * Check fields in form
   *
   * @return {void}
   */
  function isValidForm() {
    if (comment === "") {
      swal({
        title: "Datos incompletos",
        text: "Por favor ingrese los comentarios del trabajo",
        icon: "warning",
        button: "Aceptar"
      });
      return false;
    }
    if (containDoubleQuotation(comment)) {
      swal({
        title: "Advertencia",
        text: "Textos ingresados no deben contener comillas dobles (\") ",
        icon: "warning",
        button: "Aceptar"
      });
      return false;
    }
    if (!file) {
      swal({
        title: "Datos incompletos",
        text: "Por favor adjunte el archivo que contiene la asignación",
        icon: "warning",
        button: "Aceptar"
      });
      return false;
    }

    return true;
  }

  /**
   * Upload file
   *
   * @return {void}
   */
  function uploadFile() {

    return new Promise((resolve, reject) => {

      setIsUploading(true);

      let extension = file.name.split(".").pop();
      const filename = seedName + "." + extension;

      let storageRef = firebase.storage().ref('answers/' + filename);
      let task = storageRef.put(file);

      task.on('state_changed',

        function progress(snapshot) {
          let porcentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(Math.ceil(porcentage));
          setProgressPorcentage(Math.ceil(porcentage));
        },
        function error(err) {
          console.log(err);
          swal({
            title: "Error de conexión",
            text: "Se ha producido un error al subir el archivo",
            icon: "warning",
            button: "Aceptar"
          });
        },
        function complete() {
          console.log('Upload complete');
          setIsUploading(false);
          resolve();
        }
      );

    });
  }

  return(
    <>
      <h6 className="heading-small text-muted mb-4">Datos de entrega</h6>
      <div className="pl-lg-4">
        {/* Textarea input coments */}
        <FormGroup>
          <label>Comentarios</label>
          <Input
            className="form-control-alternative"
            placeholder="Ingrese comentarios sobre la entrega de la asignación"
            rows="4"
            type="textarea"
            onChange={(event) => setComment(event.target.value)}
          />
        </FormGroup>
        {/* Select input file */}
        {!isUploading ?
          <FormGroup>
            <Label for="file">Trabajo</Label>
            <Input type="file" name="file" id="file" onChange={(event) => setFile(event.target.files[0])} />
            <FormText color="muted">
              Adjunte el archivo que contiene la asignación a entregar.
            </FormText>
          </FormGroup>
          :
          <>
            <span>Subiendo el material... {progressPorcentage}%</span><br></br>
            <Progress color="info" value={progressPorcentage} />
          </>
        }
        {/* Submit button */}
        <Button color="primary" disabled={isUploading} onClick={() => addAnswer()}>Entregar</Button>{' '}
      </div>
    </>
  );
}

// Component view details of assignment
function DetailsAssignment() {

  // User logged in
  const user = useSelector(store => store.user.profile);
  const rolId = user.rol_id;

  const isTeacher = (rolId === 2);

  // Get assignments for role
  let assignments;
  if (isTeacher)
    assignments = useSelector(store => store.profesores.currentTeacher.asignaciones);
  else
    assignments = useSelector(store => store.alumnos.currentStudent.asignaciones);

  // Id of assignment
  let { id } = useParams();

  // Filter assignment selected
  const assignment = assignments.find(element => element.id === id);

  // Convert datetime end of assignment
  const dateTimeEnd = moment(assignment.fecha_fin);

  /**
   * Get status of assignment
   * 
   * @param  {object} assignment 
   * @return {array}
   */
  function getStatus(assignment) {

    let status;

    if (isTeacher)
      status = getStatusForTeacher(assignment);
    else
      status = getStatusForStudent(assignment);

    return(
      <>
        <Badge color="" className="badge-dot mr-4">
          <i className={status.icon} />
          {status.text}
        </Badge>
      </>
    );
  }

  /**
   * Evaluate status of assignment for teacher
   * 
   * @param  {object} assignment 
   * @return {array}
   */
  function getStatusForTeacher(assignment) {
    return (isCurrent(assignment)) ? {icon: 'bg-success', text: 'Publicada'} : {icon: 'bg-gray', text: 'Finalizada'};
  }

  /**
   * Evaluate status of assignment for student
   * 
   * @param  {object} assignment 
   * @return {object}
   */
  function getStatusForStudent(assignment) {

    if (assignment.entrega)
      return {icon: 'bg-success', text: 'Entregada'};
    else if (isCurrent(assignment))
      return {icon: 'bg-warning', text: 'Pendiente por entregar'};
    else
      return {icon: 'bg-danger', text: 'No realizada'};
  }

  /**
   * Check assignment is current
   * 
   * @param  {object} assignment
   * @return {boolean} 
   */
  function isCurrent(assignment) {

    let deliveryDate = new Date(moment(assignment.fecha_fin).format("YYYY-MM-DD") + " " + assignment.hora_fin);
    let dateNow = new Date();

    return dateNow < deliveryDate;
  }

  /**
   * Render view detail of answer(s) for role
   *
   * @param  {object} assignment
   * @return {object}
   */
  function getDetailAnswer(assignment) {
    if (isTeacher)
      return <ListAnswers answers={assignment.entregas} />;
    else {
      return (assignment.entrega) ? <DetailAnswer answer={assignment.entrega} /> : <FormSendAnswer user={user} assignmentId={assignment.id} />;
    }
  }

  return(
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{assignment.encabezado}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Description */}
                <Row className="align-items-center">
                  <Col>
                    <p>{assignment.descripcion}</p>
                  </Col>
                </Row>

                <Form>
                  <h6 className="heading-small text-muted mb-4">Información</h6>
                  <div className="pl-lg-4">
                    <Row style={{marginBottom: '10px'}}>
                      <Col lg="4">
                        <h3 className="mb-0">Fecha de entrega: </h3> <span>{getNameDay(dateTimeEnd.weekday())} {dateTimeEnd.date()} de {getNameMonth(dateTimeEnd.month())}</span>
                      </Col>
                      <Col lg="4">
                        <h3 className="mb-0">Estado: </h3> <span>{getStatus(assignment)}</span>
                      </Col>
                      <Col lg="4">
                        <h3 className="mb-0">Profesor: </h3> <span>{getNameFormat(assignment.profesor.nombres)}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <h3 className="mb-0">Periodo: </h3> <span>{getSufijoNumber(assignment.periodo)}</span>
                      </Col>
                      <Col lg="4">
                        <h3 className="mb-0">Área: </h3> <span>{formatTitleToDiminuto(assignment.area.nombre)}</span>
                      </Col>
                      <Col lg="4">
                        <h3 className="mb-0">Sección: </h3> <span>{assignment.seccion}</span>
                      </Col>
                    </Row>
                  </div>

                  <h6 className="heading-small text-muted mb-4" style={{marginTop: '30px', marginBottom: '10px !important'}}>Material adjunto</h6>
                  <div className="pl-lg-4">
                    <Row style={{marginBottom: '10px'}}>
                      <Col lg="4">
                        {/* Download action */}
                        <Button className="btn-icon btn-3" color="info" outline type="button">
                          <span className="btn-inner--text" onClick={() => downloadResource("assignments/" + assignment.recurso)}>Descargar</span>
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />

                  {/* View detail answer(s) */}
                  {getDetailAnswer(assignment)}
                  
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DetailsAssignment;