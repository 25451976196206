/**
 * Nro. current version
 *
 * @var {string} VERSION
 */
export const VERSION = '1.2.1';

/**
 * URL of Server GraphQL
 *
 * @var {string} URL
 */
export const URL = 'https://api.colegiosigloxxi.net';

/**
 * Configuration of Space Digital Ocean (S3)
 *
 * @var {object} spaceDiskEnv
 */
export const spaceDiskEnv = {
  nameSpace: "sisconot",
  endpoint:  "nyc3.digitaloceanspaces.com"
};

/**
 * Configuration of Firebase
 * 
 * @var {object} firebase
 */
export const firebaseEnv = {
  apiKey: "AIzaSyCwcM-36aMFdBPm6epTpQB5iNnw36n5pRE",
  authDomain: "sisconot-app.firebaseapp.com",
  databaseURL: "https://sisconot-app.firebaseio.com",
  projectId: "sisconot-app",
  storageBucket: "sisconot-app.appspot.com",
  messagingSenderId: "309838908299",
  appId: "1:309838908299:web:8cfd6aaf5ea32a19bfbd8e",
  measurementId: "G-YE0VNZK35P"
};
