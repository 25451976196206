import { FETCHED_RESOURCES, ADD_RESOURCE, DROP_RESOURCE } from '../../actions/recursos/types';

const initialState = {
	rows: []
};

export default function (state = initialState, action) {

	let result = state;

	switch (action.type) {
		case FETCHED_RESOURCES:
			result = {
        ...state,
        rows: action.payload
      }; 
	  break;
	  case ADD_RESOURCE:
			result = {
				...state
			}; 
      break;
    case DROP_RESOURCE:
      let rows = state.rows.filter(row => row.id !== action.payload);
      result = {
        ...state,
        rows
      };
      break;
		default:
      result = state;
      break;
	}

	return result;
}