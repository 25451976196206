import { FETCHED_USERS, CHANGE_ACCESS_USER } from '../../actions/usuarios/types';

const initialState = {
	rows: []
};

export default function (state = initialState, action) {

	let result = state;

	switch (action.type) {
		case FETCHED_USERS:
			result = {
				...state,
				rows: action.payload
			}; 
      break;
		case CHANGE_ACCESS_USER:
			result = {
				...state
			};
			break;
		default:
      result = state; 
      break;
	}

	return result;
}