import { FETCHED_STUDENT, FETCHED_STUDENTS } from "../alumnos/types";
import { FETCHED_TEACHER, FETCHED_TEACHERS } from "../profesores/types";
import { FETCHED_USERS }                     from "../usuarios/types";
import { FETCHED_SCHEDULES }                 from "../horarios/types";
import { FETCHED_PERIODS }                   from "../periodos/types";
import { FETCHED_SECTIONS }                  from "../secciones/types";
import { FETCHED_SUBJECTS_FOR_PERIOD }       from "../areas/types";
import { FETCHED_RESOURCES }                 from "../recursos/types";

import { request } from 'graphql-request';
import { URL } from '../../../.env';

import { getStudents } from "../alumnos";

export function getInitialData(role, cedula = null) {

  let query = ``;

  // Construir Query inicial del Rol
  switch (role) {
    // Administrador
    case 1:
      query = `{
        alumnos {
          id,
          cedula,
          nombres,
          sexo
        },
        profesores {
          id,
          cedula,
          nombres,
          sexo
        },
        usuarios {
          id,
          cedula,
          nombres,
          correo,
          avatar,
          rol,
          activo,
          ultimaConexion
        },
        horarios {
          id,
          seccion,
          area {
            codigo,
            nombre
          },
          periodo,
          dia,
          hora_inicio,
          hora_fin
        },
        periodos,
        secciones {
          seccion
        },
        areasDelPlan {
          semestre,
          areas {
            codigo,
            nombre
          }
        },
        recursos {
          id,
          nombre, 
          descripcion, 
          area {
            codigo,
            nombre
          }, 
          periodo, 
          cedula_autor, 
          ubicacion
        }
      }`;
      break;
    case 2:
      query =  `{
        profesor (cedula: "${cedula}") {
          clases {
            area {
              codigo,
              nombre
            },
            periodo,
            seccion
          }
          asignaciones {
            id,
            encabezado,
            descripcion,
            area {
              codigo,
              nombre,
              corto
            },
            periodo,
            seccion,
            profesor {
              cedula,
              nombres,
            },
            fecha_inicio,
            fecha_fin,
            hora_inicio,
            hora_fin,
            recurso,
            entregas {
              id,
              descripcion,
              alumno {
                cedula,
                nombres
              },
              fecha,
              hora,
              ubicacion
            }
          }
        }
        areasDelPlan {
          semestre,
          areas {
            codigo,
            nombre
          }
        },
        recursos {
          id,
          nombre, 
          descripcion, 
          area {
            codigo,
            nombre
          }, 
          periodo, 
          cedula_autor, 
          ubicacion
        }
    }`;
      break;
    // Alumno
    case 4:
      query =  `{
        alumno (cedula: "${cedula}") {
          id,
          asignaciones {
            id,
            encabezado,
            descripcion,
            area {
              codigo,
              nombre,
              corto
            },
            periodo,
            seccion,
            profesor {
              cedula,
              nombres,
            },
            fecha_inicio,
            fecha_fin,
            hora_inicio,
            hora_fin,
            recurso,
            entrega {
              id,
              descripcion,
              alumno {
                cedula,
                nombres
              },
              fecha,
              hora,
              ubicacion
            }
          }
        }
        recursos {
          id,
          nombre, 
          descripcion, 
          area {
            codigo,
            nombre
          }, 
          periodo, 
          cedula_autor, 
          ubicacion
        }
      }`;
      break;
    default:
      break;
  }

  // Consultar datos iniciales del Rol
  return dispatch => {
		request(URL + '/graphqll', query).then(data => {

      switch (role) {
        // Administrador
        case 1:
          // dispatch({ type: FETCHED_STUDENTS,             payload: data.alumnos });
          dispatch(getStudents());
          dispatch({ type: FETCHED_TEACHERS,             payload: data.profesores });
          dispatch({ type: FETCHED_USERS,                payload: data.usuarios });
          dispatch({ type: FETCHED_SCHEDULES,            payload: data.horarios });
          dispatch({ type: FETCHED_PERIODS,              payload: data.periodos });
          dispatch({ type: FETCHED_SECTIONS,             payload: data.secciones });
          dispatch({ type: FETCHED_SUBJECTS_FOR_PERIOD,  payload: data.areasDelPlan });
          dispatch({ type: FETCHED_RESOURCES,  payload: data.recursos });
          break;
        // Profesor
        case 2:
          dispatch({ type: FETCHED_TEACHER,   payload: data.profesor });
          dispatch({ type: FETCHED_SUBJECTS_FOR_PERIOD,  payload: data.areasDelPlan });
          dispatch({ type: FETCHED_RESOURCES, payload: data.recursos });
          break;
        // Alumno
        case 4:
          dispatch({ type: FETCHED_STUDENT,    payload: data.alumno });
          dispatch({ type: FETCHED_RESOURCES,  payload: data.recursos });
          break;
      
        default:
          break;
      }
		  
		})
	}
}