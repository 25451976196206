import { FETCHED_STUDENTS, FETCHED_STUDENT, FETCHED_ASSIGNMENTS } from '../../actions/alumnos/types';

const initialState = {
  tuition: {
    rows: [],
    numberPages: 0,
    currentPage: 1
  },
  currentStudent: {
    assignments: []
  }
};

export default function (state = initialState, action) {

	let result = state;

	switch (action.type) {
		case FETCHED_STUDENTS:
			result = {
				...state,
				tuition: action.payload
			}; 
      break;
    case FETCHED_STUDENT:
      result = {
        ...state,
        currentStudent: action.payload
      };
      break;
    case FETCHED_ASSIGNMENTS:
      let currentStudent = state.currentStudent;
      currentStudent.asignaciones = action.payload
      result = {
        ...state,
        currentStudent
      };
      break;
		default:
      result = state; 
      break;
	}

	return result;
}