import { FETCHED_STUDENTS, FETCHED_ASSIGNMENTS } from "./types";

import { request } from 'graphql-request';
import { URL } from '../../../.env';

/*
export function getStudents() {

	const query = `{
    alumnos {
      id,
      cedula,
      nombres,
      sexo
    }
  }`;

  return dispatch => {
		request(URL + '/graphqll', query).then(data => {
		  dispatch({
		    type: FETCHED_STUDENTS,
		    payload: data.alumnos
		  }); 
		})
	}
}
*/

// TEMP
import Factory from "../../../util/Factories";
export function getStudents(currentPage = 1) {

  // API Implementation
  let students = []

  if (!localStorage.getItem('tuition')) {

    let data = Factory('tuition');

    localStorage.setItem('tuition', JSON.stringify(data));

    data = {
      "rows": data[0],
      "numberPages": data.length,
      "currentPage": 1
    };

    students = data

  } else {

    let data = localStorage.getItem('tuition');
    data = JSON.parse(data);

    data = {
      "rows": data[currentPage-1],
      "numberPages": data.length,
      "currentPage": currentPage
    };

    students = data;

  }

  // API Implementation
  return dispatch => {
    dispatch({
      type: FETCHED_STUDENTS,
      payload: students
    }); 
  }

}


export function getAssignments(cedula) {

	const query = `{
    alumno (cedula: "${cedula}") {
      asignaciones {
        id,
        encabezado,
        descripcion,
        area {
          codigo,
          nombre,
          corto
        },
        periodo,
        seccion,
        profesor {
          cedula,
          nombres,
        },
        fecha_inicio,
        fecha_fin,
        hora_inicio,
        hora_fin,
        recurso,
        entrega {
          id,
          descripcion,
          alumno {
            cedula,
            nombres
          },
          fecha,
          hora,
          ubicacion
        }
      }
    }
  }`;

	return dispatch => {
		request(URL + '/graphqll', query).then(data => {
			dispatch({
				type: FETCHED_ASSIGNMENTS,
				payload: data.alumno.asignaciones
			});
		})
	}
}

export function createAnswer(answer, cedula) {

	const query = `mutation {
    entregarAsignacion (entrega: {
    	descripcion:   "${answer.descripcion}",
      asignacion_id:  ${answer.asignacion_id},
      alumno_id:      ${answer.alumno_id},
      fecha:         "${answer.fecha}",
      hora:          "${answer.hora}",
      ubicacion:     "${answer.ubicacion}"
    })
  }`;

	return dispatch => {

		request(URL + '/graphqll', query).then(data => {

			dispatch(getAssignments(cedula));

		})

	}

}