/*eslint-disable*/

import React, { useEffect, useRef, Suspense, lazy } from "react";

import { Route, Switch, Redirect } from "react-router-dom";

// Reactstrap Components
import { Container } from "reactstrap";

// Core Components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import AdminFooter from "../components/Footers/AdminFooter.js";
import Sidebar from "../components/Sidebar/Sidebar.js";

import otherRoutes from "../routes.js";

import { useDispatch, useSelector } from 'react-redux';
import { getMenus } from '../redux/actions/menu';
import { getInitialData } from "../redux/actions/initial";

import Index from '../views/Index';

import Loader from "../components/Utils/Loader";

function Admin(props) {

  const dispatch = useDispatch();

  const user = useSelector(store => store.user.profile);

  const routes = useSelector(store => {
    return store.menu.menus.map(menu => ({
      path: menu.ruta,
      name: menu.nombre,
      icon: menu.icono + " text-prima",
      component: lazy(() => import("../views/roles/" + getRoleName(user.rol_id) + "/" + menu.nombre)),
      layout: "/admin"
    }))
  });

  function getRoleName(role) {
    switch (role) {
      case 1:
        return "Administrador";
      case 2:
        return "Profesor";
      case 4:
        return "Alumno";
      default:
        return "Alumno";
    }
  }

  const mainContent = useRef(null);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;

    const roleLogged = user.rol_id;
    const role = roleLogged || 1;

    dispatch(getMenus(role));
    dispatch(getInitialData(role, user.cedula));
  }, []);

  function getRoutes(routes) {

    routes = [
      ...routes,
      ...otherRoutes
    ];

    return routes.map((prop, key) => {

      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  }

  function getBrandText(path) {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "";
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("assets/img/brand/logo.png"),
          imgAlt: "..."
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Suspense fallback={<Loader />}>
          <Switch>
            {getRoutes(routes)}
          </Switch>
        </Suspense>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
}

export default Admin;