import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Main Styles
import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import "./assets/css/performance-added.css"

// Layouts of template
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";

// Initialize store Redux
import { Provider, useDispatch } from "react-redux";
import store from './redux';
import { saveProfile } from "./redux/actions/user";

import { useHistory } from "react-router-dom";

// Firebase dependencies
import firebase from 'firebase/app';
import 'firebase/storage';

// Enviroment variables
import { firebaseEnv, URL } from '.env';

// Axios HTTP dependence
const axios = require('axios');

// REMOVER --------------------------------
// Initialize Firebase App
firebase.initializeApp(firebaseEnv);
// Get a reference to the storage service
let storage = firebase.storage();
// Create a storage reference
window.storageRef = storage.ref();
// REMOVER --------------------------------

// Component for Redirect if is logged
function IsLoggedIn() {

  const dispatch = useDispatch();
  const history  = useHistory();

  // Status of request
  const [status, setStatus] = useState("waiting");

  useEffect(() => {
    // Send request for check status session
    axios({
      method: 'get',
      url: URL+'/',
      withCredentials: true
    }).then((response) => {
      // Check status session
      if (Object.entries(response.data).length > 0) {
        dispatch(saveProfile(response.data));
        setStatus("loggedIn");
      } else
        setStatus("loggedOut");
    });
  }, [dispatch]);

  // Eval url for status
  if (status === "waiting") {
    return <></>;
  } else if (status === "loggedIn") {
    history.push("/admin/index");
    return <></>;
  } else if (status === "loggedOut") {
    history.push("/auth/login");
    return <></>;
  }
}

export function Main() {
	return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={props => <AdminLayout {...props} />} />
          <Route path="/auth" render={props => <AuthLayout {...props} />} />
          <IsLoggedIn />
        </Switch>
      </BrowserRouter>
    </Provider>
	);
}

ReactDOM.render(
  <Main />,
  document.getElementById("root") || document.createElement('div')
);