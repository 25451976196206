import { 
	FETCHED_USER_PROFILE, 
	ADD_USER_PROFILE,
	LOGOUT_USER_PROFILE
} from './types';
import { request } from 'graphql-request';

import { URL } from '../../../.env';

const axios = require('axios');

export function getProfile () {

	const query = `{
	  usuario(id: 1) {
	    id,
	    cedula
	  }
	}`;
	
	return dispatch => {
		request(URL + '/graphqll', query).then(data => {
		  dispatch({
		    type: FETCHED_USER_PROFILE,
		    payload: data
		  }); 
		})
	}
}

export function saveProfile (profile) {
	return {
    type: ADD_USER_PROFILE,
    payload: profile
  }
}

export function logout () {
	return dispatch => {
		axios({
			method: 'get',
			url: URL + '/logout',
			withCredentials: true
		}).then(response => {
			dispatch({ type: LOGOUT_USER_PROFILE, payload: {} });
		})
  }
}