import { 
  FETCHED_USER_PROFILE, 
  ADD_USER_PROFILE,
  LOGOUT_USER_PROFILE
} from '../../actions/user/types';

const initialState = {
  profile: {},
  loggedIn: false
};

export default function (state = initialState, action) {

	let result = state;

	switch (action.type) {
		case FETCHED_USER_PROFILE:
			result = {
        ...state,
        profile: action.payload
      }; 
      break;
    case ADD_USER_PROFILE:
			result = {
        ...state,
        profile: action.payload,
        loggedIn: true
      }; 
      break;
    case LOGOUT_USER_PROFILE:
      result = {
        ...state,
        profile: action.payload,
        loggedIn: false
      }; 
      break;
		default:
      result = state; 
      break;
	}

	return result;
}