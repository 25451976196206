import { FETCHED_TEACHERS, FETCHED_TEACHER, FETCHED_ASSIGNMENTS } from '../../actions/profesores/types';

const initialState = {
	tuition: [],
	currentTeacher: {
		assignments: []
	}
};

export default function (state = initialState, action) {

	let result = state;

	switch (action.type) {
		case FETCHED_TEACHERS:
			result = {
				...state,
				tuition: action.payload
			}; 
      break;
    case FETCHED_TEACHER:
      result = {
        ...state,
				currentTeacher: action.payload
      };
      break;
		case FETCHED_ASSIGNMENTS:
			let currentTeacher = state.currentTeacher;
			currentTeacher.asignaciones = action.payload
			result = {
				...state,
				currentTeacher
			};
			break;
		default:
      result = state; 
      break;
	}

	return result;
}