import React, { useEffect } from "react";

import { Container } from "reactstrap";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

// Core Components
import Header from "../Headers/Header.js";

import './Loader.css';

function Loader(props) {

  const history  = useHistory();

  const routes = useSelector(store => {
    return store.menu.menus.map(menu => ({
      path: menu.ruta
    }))
  });

  useEffect(() => {
    if ((props.match) && (props.match.path.split("/")[2] == 'index')) {
      if (routes.length > 0) {
        console.log(routes[0].path);
        history.push("/admin" + routes[0].path);
      }
    }
  }, [routes]);

  return(
    <>
      <Header />
      <Container fluid style={{transform: 'translateY(-90px)'}}>
        <div className="book">
          <div className="book__page"></div>
          <div className="book__page"></div>
          <div className="book__page"></div>
          <span style={{transform: 'translate(8px, 65px)', color: '#ffffff', position: 'absolute', textShadow: '0.4px 0.4px #000000'}}>Cargando...</span>
        </div>
      </Container>
    </>
  );
}

export default Loader;